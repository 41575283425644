<template>
  <div
    class="mem-phone-field"
    :class="{
      'is-focused': activeClass.isFocued,
      'has-error': hasError,
      'mem-light': light,
    }"
    v-bind="$attrs"
    ref="memPhoneField"
  >
    <div class="field-label">{{ label }}</div>
    <v-menu
      offset-y
      :nudge-top="7"
      max-height="212"
      :max-width="dropdownMaxWidth"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="menu-activator">
          <div v-on="on" v-bind="attrs" class="code-selector">
            <div class="selected-code">
              <span>{{ "+" + selectedCode }}</span>
              <i class="material-icons notranslate"> arrow_drop_down </i>
            </div>
          </div>
          <v-text-field
            :light="light"
            :color="light ? '#8D8D8D' : ''"
            v-model="selectedPhone"
            type="tel"
            :error="hasError"
            :error-messages="fieldErrors"
            @focus="onFocus"
            @blur="onBlur"
          ></v-text-field>
        </div>
      </template>
      <div class="codes-list" :style="{ 'max-width': menuMaxWidth }">
        <div
          v-for="(code, index) in phoneCodes"
          :key="index"
          class="code-item"
          @click="onCodeChange(code)"
        >
          <div class="code-country">
            {{ $t("countries." + code.country_code) }}
          </div>
          <div class="code-value">+{{ code.phone_code }}</div>
        </div>
      </div>
    </v-menu>
  </div>
</template>
<script>
import { findIndex } from "lodash";
import { phoneCodes } from "@/lists";
import parsePhoneNumber from "libphonenumber-js";

export default {
  name: "BasePhoneFieldV2",
  inheritAttrs: false,
  data: () => ({
    selectedCode: "",
    selectedPhone: "",
    selectedCountyCode: "",
    activeClass: {
      isFocued: false,
    },
    phoneCodes,
    hasError: false,
    fieldErrors: [],

    dropdownMaxWidth: 0,
  }),
  computed: {
    defaultCode() {
      let index = findIndex(this.phoneCodes, {
        country_code: this.phoneCountry,
      });
      if (index === -1) return "1";
      return this.phoneCodes[index].phone_code;
    },
    menuMaxWidth() {
      return `calc(100vw - ${this.menuOffset * 2}px)`;
    },
  },
  props: {
    label: String,
    phoneCountry: {
      type: String,
      default: "US",
    },
    phoneData: {
      type: Object,
      default: () => ({}),
    },
    menuOffset: {
      type: Number,
      default: 24,
    },
    light: {
      type: Boolean,
      default: false,
    },
    validateField: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedCode: {
      //   immediate: true,
      handler(code) {
        if (!this.selectedPhone) return;
        this.$emit("input", `${code}${this.selectedPhone}`);
      },
    },
    selectedPhone: {
      //   immediate: true,
      handler(phone) {
        this.$emit("input", `${this.selectedCode}${phone}`);
      },
    },
    phoneData: {
      immediate: true,
      handler(data) {
        let { code, number, country } = data || {};
        this.selectedCode = code || this.defaultCode;
        this.selectedPhone = number || "";
        this.selectedCountyCode = country || this.phoneCountry;

        // validate if field not empty
        if (!number) return;
        this.validateNumber();
      },
    },
    validateField: {
      handler(val) {
        if (!val) return;
        this.validateNumber();
      },
    },
  },
  created() {
    window.addEventListener("resize", this.setDropdownMenuWidth);
    this.$nextTick(() => {
      this.dropdownMaxWidth = this.$refs.memPhoneField.offsetWidth;
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.setDropdownMenuWidth);
  },
  methods: {
    onFocus() {
      this.hasError = false;
      this.fieldErrors = [];
      this.activeClass.isFocued = true;
    },
    onBlur() {
      this.activeClass.isFocued = false;
      this.validateNumber();
    },
    validateNumber() {
      const phoneNumber = parsePhoneNumber(
        `${this.selectedCode} ${this.selectedPhone}`,
        this.selectedCountyCode
      );
      if (!phoneNumber || !phoneNumber?.isValid()) {
        this.hasError = true;
        this.fieldErrors.push(this.$t("invalid_phone_number"));
      }
    },
    onCodeChange(item) {
      console.log(item, "Selected item");
      this.selectedCode = item.phone_code;
      this.selectedCountyCode = item.country_code;
    },
    setDropdownMenuWidth() {
      this.dropdownMaxWidth = this.$refs.memPhoneField.offsetWidth;
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-activator {
  display: grid;
  grid-template-columns: max-content 1fr;
}
.code-selector {
  min-width: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 7px;
  .selected-code {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    line-height: 20px;
    position: relative;
    .material-icons {
      font-size: 16px;
    }
    &:before {
      content: "";
      height: 1px;
      background-color: rgba(255, 255, 255, 0.4);
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }
}
.codes-list {
  background-color: #2a2929;
  // max-width: calc(100vw - 48px);
  .code-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: 32px;
    margin-bottom: 6px;
    font-size: 16px;
    min-width: 0;
    @include cursorPointer;
    @media screen and (max-width: $mobile) {
      // min-width: 0;
      // max-width: 326px;
      // padding-right: 0;
    }
    .code-value {
      margin-left: 6px;
    }
    .code-country {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .code-item:hover {
    background-color: #3f3e3e;
  }
}
.mem-phone-field {
  position: relative;
  padding-bottom: 14px;
  .field-label {
    position: absolute;
    color: #757474;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    transform: scale(0.75);
    transform-origin: top left;
  }

  ::v-deep(.v-input) {
    .v-text-field__details {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
.is-focused {
  .code-selector {
    .selected-code {
      &:before {
        background-color: #ffffff;
        transition-property: background-color;
        transition-delay: 0.28s;
        // transition-duration: 0.1s;
      }
    }
  }
}
.mem-light {
  .field-label {
    color: #8d8d8d;
    font-weight: 500;
  }
  .selected-code {
    &:before {
      background-color: rgba(0, 0, 0, 0.42);
    }
  }
  &.is-focused {
    .selected-code {
      &:before {
        background-color: #8d8d8d;
      }
    }
  }
  &:hover:not(.is-focused) {
    .selected-code {
      &::before {
        background-color: #000000;
      }
    }
  }
  &.has-error {
    .selected-code {
      &::before {
        background-color: red;
      }
    }
  }
}
.has-error {
  .field-label {
    color: red;
  }
}
</style>